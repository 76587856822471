<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-11-07 15:37:39
 * @LastEditors: pj
 * @LastEditTime: 2022-11-16 14:56:44
-->
<template>
  <div class="sighIn">
    <div class="sighIn-title">注册会员</div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span v-html="showContent"></span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <div class="sighIn-content">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        label-position="top"
        class="sighIn-ruleForm"
      >
        <el-form-item prop="phone">
          <el-input v-model.number="ruleForm.phone" placeholder="手机号">
            <el-select
              v-model="select"
              slot="prepend"
              placeholder="请选择"
              style="width: 125px"
            >
              <el-option label="中国大陆" value="86"></el-option>
              <el-option label="中国澳门" value="853"></el-option>
              <el-option label="中国香港" value="852"></el-option>
              <el-option label="中国台湾" value="886"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item prop="phoneCode">
          <el-input
            v-model="ruleForm.phoneCode"
            placeholder="请输入验证码"
            autocomplete="off"
          >
          </el-input>
          <el-button
            class="sighIn-codeButton"
            type="primary"
            :loading="buttonStatus"
            @click="getCode"
            >{{ buttonText }}</el-button
          >
        </el-form-item>

        <el-form-item prop="loginPassword">
          <el-input
            type="password"
            placeholder="请输入登录密码6-20位"
            v-model="ruleForm.loginPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="loginPassword2">
          <el-input
            type="password"
            placeholder="请再次输入密码"
            v-model="ruleForm.loginPassword2"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item prop="tradePassword">
          <el-input
            type="password"
            placeholder="请输入6位数喜付密码"
            v-model="ruleForm.tradePassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <div class="sighIn-content-notice">
          <el-form-item prop="type">
            <el-checkbox-group v-model="ruleForm.type">
              <el-checkbox
                label="我已阅读并接受用户协议和隐私政策"
                name="type"
                @change="showText"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <div style="margin-bottom: 20px">忘记密码</div>
        </div>

        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            class="sighIn-login"
            :loading="sighStatus"
            >下一步</el-button
          >

          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div @click="toSighUp">已有账号，马上登录</div>
  </div>
</template>

<script>
import {
  sendSms,
  sighIn,
  getPublicKey_Api,
  getpass_Api,
  getAgreement,
} from '@/api/sigh/index.js'
export default {
  name: 'SighIn',
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }

      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (this.select === '') {
            callback(new Error('请选择区号'))
          } else if (this.select === '86') {
            if (!/^1[3-9]\d{9}$/.test(value)) {
              callback(new Error('请输入符合要求的手机号码'))
            } else {
              callback()
            }
          } else if (this.select === '853') {
            if (!/^6\d{7}$/.test(value)) {
              callback(new Error('请输入符合要求的手机号码'))
            } else {
              callback()
            }
          } else if (this.select === '852') {
            if (!/^([5|6|9])\d{7}$/.test(value)) {
              callback(new Error('请输入符合要求的手机号码'))
            } else {
              callback()
            }
          } else if (this.select === '886') {
            console.log(value)
            if (!/^[9]\d{8}$/.test(value)) {
              callback(new Error('请输入符合要求的手机号码'))
            } else {
              callback()
            }
          }
        }
      }, 1000)
    }
    var checkCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else if (!/\d+/.test(value)) {
        callback(new Error('请输入正确的格式'))
      } else {
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6 || value.length > 20) {
        callback(new Error('请输入正确位数'))
      } else {
        if (this.ruleForm.loginPassword2 !== '') {
          this.$refs.ruleForm.validateField('loginPassword2')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.loginPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    var validatePass3 = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error('请输入六位数的喜付密码'))
      } else if (!/\d+/.test(value)) {
        callback(new Error('请输入正确的格式'))
      } else {
        callback()
      }
    }

    return {
      ruleForm: {
        phone: '',
        type: [],
        phone: '',
        phoneCode: '',
        loginPassword: '',
        tradePassword: '',
        loginPassword2: '',
        invitationCode: '',
      },
      dialogVisible: false,
      showContent: '',
      rules: {
        loginPassword: [{ validator: validatePass, trigger: 'blur' }],
        loginPassword2: [{ validator: validatePass2, trigger: 'blur' }],
        tradePassword: [{ validator: validatePass3, trigger: 'blur' }],
        phoneCode: [{ validator: checkCode, trigger: 'blur' }],
        phone: [{ validator: checkPhone, trigger: 'blur' }],
        type: [
          {
            type: 'array',
            required: true,
            message: '请勾选',
            trigger: 'change',
          },
        ],
      },
      buttonStatus: false,
      buttonText: '发送验证码',
      select: '',
      sighStatus: false,
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.sighStatus = true
          let params = {
            phone: this.ruleForm.phone,
          }
          //先进行请求公钥
          const key = await getPublicKey_Api(params)
          if (key.code === 200) {
            //再拿公钥去加密验证码
            let params = {
              key: key.data,
              password: this.ruleForm.phoneCode,
            }
            const encryptPwd = await getpass_Api(params)
            if (encryptPwd) {
              let param = { ...this.ruleForm }
              param.phoneCode = encryptPwd
              delete console.log(param, 'params')
              delete param.type
              delete param.loginPassword2
              this.sighStatus = false
              const result = await sighIn(param)
              if (result.code === 200) {
                this.$message({
                  message: '恭喜你，注册成功',
                  type: 'success',
                })
                this.$emit('update-sigh', false)
              }
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    async getCode() {
      const areaCode = this.select
      let phoneNumber = this.ruleForm.phone
      if (areaCode === '') {
        this.$message.error('请选择区号')
        return
      }
      if (phoneNumber === '') {
        this.$message.error('请输入手机号码')
        return
      }
      if (this.select === '') {
        callback(new Error('请选择区号'))
      } else if (this.select === '86') {
        if (!/^1[3-9]\d{9}$/.test(phoneNumber)) {
          this.$message.error('请输入符合要求的手机号码')
          return
        }
      } else if (this.select === '853') {
        if (!/^6\d{7}$/.test(phoneNumber)) {
          this.$message.error('请输入符合要求的手机号码')
          return
        }
      } else if (this.select === '852') {
        if (!/^([5|6|9])\d{7}$/.test(phoneNumber)) {
          this.$message.error('请输入符合要求的手机号码')
          return
        } else if (this.select === '886') {
          let newNum = Number(phoneNumber)
          if (!/^[9]\d{8}$/.test(newNum)) {
            this.$message.error('请输入符合要求的手机号码')
            return
          }
        }
      }

      const params = {
        area: areaCode,
        phone: phoneNumber,
        type: 4,
      }
      const result = await sendSms(params)
      console.log(result, 'result')
      this.buttonStatus = true
      this.buttonText = 60
      var times = setInterval(() => {
        this.buttonText-- //递减
        if (this.buttonText <= 0) {
          // <=0 变成获取按钮
          this.buttonStatus = false
          this.buttonText = '发送验证码'
          clearInterval(times)
        }
      }, 1000) //1000毫秒后执行
    },
    toSighUp() {
      this.$emit('update-sigh', false)
    },
    async showText(res) {
      if (res) {
        const result = await getAgreement({ type: 1 })
        this.showContent = result.data.textContent
        this.dialogVisible = true
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sighIn {
  width: 580px;
  padding-bottom: 40px;
  background: #f8f9fa;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-title {
    width: 100%;
    height: 73px;
    background: #323232;
    border-radius: 6px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #f8f9fa;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-content {
    margin-top: 40px;
    &-notice {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-codeButton {
    width: 156rpx;
    height: 38px;
    border-radius: 6px;
    font-size: 9px;
    color: #f8f9fa;
    margin-left: 9px;
  }

  &-ruleForm {
    width: 460px;
  }

  &-login {
    width: 460px;
    height: 38px;
    border-radius: 6px;
  }
}
</style>
