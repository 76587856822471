<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-09-22 09:34:05
 * @LastEditors: pj
 * @LastEditTime: 2022-11-16 12:44:43
-->
<template>
  <div class="login">
    <div class="login-top">
      <img src="../../assets/logo.png" />
    </div>
    <div class="login-content">
      <sighUp v-on:update-sigh="showSighIn" v-if="sighUpState"></sighUp>
      <sighIn v-on:update-sigh="showSighIn" v-if="sighInState"></sighIn>
    </div>
    <div style="margin-top: 20px; font-size: 18px">小程序上传</div>
    <img class="wechatDemo" src="../../assets/wechatDemo.jpg" alt="" />
  </div>
</template>

<script>
import sighUp from './components/sighUp.vue'
import sighIn from './components/sighIn.vue'
export default {
  name: 'Login',
  components: {
    sighUp,
    sighIn,
  },
  data() {
    return {
      sighUpState: true,
      sighInState: false,
    }
  },
  methods: {
    showSighIn(event) {
      console.log(event)
      if (event) {
        this.sighUpState = false
        this.sighInState = true
      } else {
        this.sighUpState = true
        this.sighInState = false
      }
    },
  },

  beforeCreate() {
    this.$nextTick(() => {
      document.body.setAttribute('style', 'background:#f0f0f0')
    })
  },
  //实例销毁之前钩子--移除body 标签的属性style
  beforeDestroy() {
    document.body.removeAttribute('style')
  },
}
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .wechatDemo {
    margin-top: 10px;
  }
  &-top {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    image {
      width: 162rpx;

      height: 51rpx;
    }
  }
  &-content {
    margin-top: 111px;
  }
}
</style>
