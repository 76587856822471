<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-11-07 15:37:32
 * @LastEditors: pj
 * @LastEditTime: 2022-11-16 12:56:37
-->
<template>
  <div class="sighUp">
    <div class="sighUp-title">登录账号</div>
    <div class="sighUp-content">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        label-position="top"
        class="sighUp-ruleForm"
      >
        <el-form-item prop="phone">
          <el-input
            type="tel"
            v-model="ruleForm.phone"
            placeholder="手机号"
            class="input-with-select"
          >
            <!-- <el-select
              v-model="select"
              slot="prepend"
              placeholder="请选择"
              style="width: 125px"
            >
              <el-option label="中国大陆" value="86"></el-option>
              <el-option label="中国澳门" value="853"></el-option>
              <el-option label="中国香港" value="852"></el-option>
              <el-option label="中国台湾" value="886"></el-option>
            </el-select> -->
          </el-input>
        </el-form-item>

        <el-form-item prop="pass">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            class="sighUp-login"
            :loading="sighStatus"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-button type="primary" class="sighUp-login" plain @click="toSighIn"
      >注册</el-button
    >
  </div>
</template>

<script>
import {
  getPublicKey_Api,
  getpass_Api,
  sighUp,
  getIdentityWord,
  isRelease,
} from '@/api/sigh/index.js'
export default {
  name: 'SighUp',
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      value = Number(value)
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (!/^1[3-9]\d{9}$/.test(value)) {
            callback(new Error('请输入符合要求的手机号码'))
          } else {
            callback()
          }
        }
      }, 1000)
    }
    var checkPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        pass: '',
        phone: '',
      },
      rules: {
        pass: [{ validator: checkPass, trigger: 'blur' }],
        phone: [{ validator: checkPhone, trigger: 'blur' }],
      },
      select: '',
      sighStatus: false,
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.sighStatus = true
          let params = {
            phone: this.ruleForm.phone,
          }
          //先进行请求公钥
          const key = await getPublicKey_Api(params)
          if (key.code === 200) {
            //再拿公钥去加密验证码
            let params = {
              key: key.data,
              password: this.ruleForm.pass,
            }
            const encryptPwd = await getpass_Api(params)
            if (encryptPwd) {
              this.sighStatus = false
              const fmData = new FormData()
              fmData.append('password', encryptPwd)
              fmData.append('phone', this.ruleForm.phone)
              const result = await sighUp(fmData)
              if (result.code === 200) {
                sessionStorage.setItem('X-Requested-Token', result.data.token)
                this.$message({
                  message: '登录成功',
                  type: 'success',
                })
                console.log(
                  sessionStorage.getItem('X-Requested-Token'),
                  'token'
                )
                const results = await isRelease()
                if (results.code === 505) {
                  this.$confirm('上传作品前需要先进行实名认证', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                    .then(() => {
                      this.$router.push({ path: '/IdCard' })
                    })
                    .catch(() => {})
                } else if (results.code == 500) {
                  this.$confirm('你已发布过作品，感谢支持', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                    .then(() => {
                      this.$router.push({ path: '/home' })
                    })
                    .catch(() => {})
                } else if (results.code === 200) {
                  sessionStorage.setItem('IdCard', true)
                  this.$router.push({ path: '/upload' })
                }
              }
            }
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toSighIn() {
      this.$emit('update-sigh', true)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sighUp {
  width: 580px;
  padding-bottom: 40px;
  background: #f8f9fa;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-title {
    width: 100%;
    height: 73px;
    background: #323232;
    border-radius: 6px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #f8f9fa;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-content {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &-codeButton {
    width: 156rpx;
    height: 38px;
    border-radius: 6px;
    font-size: 9px;
    color: #f8f9fa;
    margin-left: 9px;
  }

  &-ruleForm {
    width: 460px;
  }

  &-login {
    width: 460px;
    height: 38px;
    border-radius: 6px;
  }
}
</style>
